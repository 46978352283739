<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 540 170"
        style="enable-background: new 0 0 540 170"
        xml:space="preserve"
    >
        <g>
            <g>
                <g>
                    <path
                        class="st0"
                        d="M532.2,79.1c-3.8,0-7.3-2.9-7.3-7.3c0-4.2,3.3-7.3,7.3-7.3c4.2,0,7.3,3.3,7.3,7.3
				C539.5,75.9,536.4,79.1,532.2,79.1z M532.3,65.5c-3.6,0-6.4,2.9-6.4,6.4c0,3.5,2.9,6.3,6.3,6.3c3.5,0,6.3-2.8,6.3-6.3
				C538.6,68.4,535.9,65.5,532.3,65.5z M532.2,67.4c0.9,0,1.5,0,2,0.2c0.9,0.3,1.4,1.2,1.4,2c0,1.1-0.7,1.8-1.8,2.1
				c0.7,0.3,1.1,0.7,1.2,1.8c0.2,1.3,0.2,1.8,0.7,2.6h-1.8c-0.1-0.2-0.2-0.5-0.3-1.2c0-0.2-0.2-1.1-0.2-1.3c-0.2-1.2-0.8-1.3-2-1.4
				h-0.5v3.9h-1.5v-8.7L532.2,67.4L532.2,67.4z M530.9,71h1.3c0.7,0,1.9,0,1.9-1.2c0-0.3-0.2-0.8-0.7-1.1c-0.3-0.2-0.5-0.2-1.5-0.2
				h-1L530.9,71L530.9,71z"
                    />
                </g>
                <g>
                    <path
                        class="st0"
                        d="M56.5,110.4c-2.2,3.4-4.6,7.2-9.4,10.6c-4.6,3.2-10.4,5.4-17,5.4c-11.4,0-29.5-6.9-29.5-31.1
				c0-17.3,10-31.9,29.9-31.9c6.6,0,17.9,1.8,25.4,14.3l-13,7.4c-1.1-2.2-2.2-4.2-5-6.3c-1.7-1.1-3.9-2-6.6-2
				c-9.5,0-12.6,10.8-12.6,18c0,7.8,3.8,17.8,13.2,17.8c0.9,0,5.2,0,8.9-4.1c1.5-1.7,2-3.1,2.6-4.8L56.5,110.4z"
                    />
                    <path
                        class="st0"
                        d="M119.9,94.6c0,15-8.6,31.6-31.9,31.6c-16.7,0-32-9.5-32-31.6c0-18.2,11.5-32.7,34.3-31.4
				C114.5,64.6,119.9,83.8,119.9,94.6z M76.6,82.8c-2.2,3.4-2.9,7.5-2.9,11.6c0,9.5,4.6,17.8,14.4,17.8c9.5,0,14.1-7.7,14.1-17
				c0-6.6-1.6-11.7-4.9-14.9c-3.7-3.4-7.7-3.5-10.3-3.3C82,77.4,79,79.1,76.6,82.8z"
                    />
                    <path
                        class="st0"
                        d="M139,71.3c2-2.4,6.6-7.7,16.9-7.7c9.8,0,13.3,3.7,16.6,6.9c3.4-2.9,8.2-6.9,17.7-6.9
				c9.8,0,14.8,4.9,16.6,8.6c1.5,3.2,1.6,7.3,1.6,13.7v38.7h-17.3V93.3c0-3.4,0-7.8-0.7-10.1c-1.6-5-6.3-5.4-7.7-5.4
				c-1.7,0-4,0.5-5.7,1.8c-3.3,2.6-3.3,6-3.3,12.6v32.4h-17.3V89.4c0-2.6-0.1-6.1-1.7-8.4c-1.4-1.8-4-3.1-6.9-3.1
				c-1.4,0-3.2,0.3-4.8,1.4c-3.9,2.6-4,7.4-4,11.5v33.9h-17.3V65.2h17.3L139,71.3L139,71.3z"
                    />
                    <path
                        class="st0"
                        d="M227.9,70.8c0.6-0.7,1.1-1.4,1.7-2c2.9-2.9,8.3-5.2,14.9-5.2c14.6,0,25.9,11,25.9,30.8
				c0,12.2-5.4,31.1-25.8,31.1c-6.6,0-10-2.2-14.7-5v25.4l-17.6,2.4v-83h15.6V70.8z M241.8,76.1c-3.2,0-6.5,1.4-9,4.8
				c-2.5,3.3-3.7,8.4-3.7,14c0,7.5,2,12.2,4.2,14.7c2,2.2,5,3.4,7.8,3.4c8.6,0,12.3-9.2,12.3-18.6c0-7.8-2.2-16.2-9.4-18
				C243.4,76.1,242.6,76.1,241.8,76.1z"
                    />
                    <path
                        class="st0"
                        d="M274.2,81.9c0.3-4.1,0.9-9,6.3-13.2c5.5-4.3,12.6-5.2,19.5-5.2c7.3,0,25,1.7,25,17v34.7
				c0,6.4,0.3,7.3,1.7,9.4h-17.2v-5.5c-4.4,3.3-9.5,7.1-18.2,7.1c-13.7,0-19.5-9.8-19.5-18.1c0-9.5,7.7-15,12.6-17
				c4.8-2,16.1-4.1,23.5-5.5v-3.2c-0.2-2.8-0.5-7.3-6.7-7.3c-7.7,0-8.8,4.8-9.2,6.9h-17.7V81.9z M295.7,99.2
				c-3.3,1.3-8.5,3.2-8.5,8.3c0,3.2,2.9,6.8,7.4,6.8c2.4,0,4.7-0.9,6.8-2.2c6.5-4.1,6.8-10.3,6.9-17.2L295.7,99.2z"
                    />
                    <path
                        class="st0"
                        d="M383.3,110.4c-2.2,3.4-4.6,7.2-9.4,10.6c-4.6,3.2-10.4,5.4-17,5.4c-11.4,0-29.5-6.9-29.5-31.1
				c0-17.3,10-31.9,29.9-31.9c6.6,0,17.9,1.8,25.4,14.3l-13,7.4c-1.1-2.2-2.2-4.2-5-6.3c-1.7-1.1-3.8-2-6.6-2
				c-9.5,0-12.6,10.8-12.6,18c0,7.8,3.9,17.8,13.2,17.8c0.9,0,5.2,0,8.9-4.1c1.5-1.7,2-3.1,2.6-4.8L383.3,110.4z"
                    />
                    <path
                        class="st0"
                        d="M391.3,50.1l17.5-2.4v17h11.5v10.7h-11.6v28.3c-0.1,3.7-0.1,6.7,4.6,6.7l6.3-0.2v13.7
				c-3.8,0.6-6,0.9-9.2,0.9c-6,0-12.6-0.3-16.4-5.8c-2.4-3.5-2.5-7.7-2.5-13.5V75.3h-7.7V64.6h7.7L391.3,50.1L391.3,50.1z"
                    />
                    <path
                        class="st0"
                        d="M483.7,94.6c0,15-8.6,31.6-31.9,31.6c-16.7,0-32-9.5-32-31.6c0-18.2,11.5-32.7,34.3-31.4
				C478.3,64.6,483.7,83.8,483.7,94.6z M440.4,82.8c-2.2,3.4-2.9,7.5-2.9,11.6c0,9.5,4.6,17.8,14.4,17.8c9.5,0,14.1-7.7,14.1-17
				c0-6.6-1.6-11.7-4.9-14.9c-3.7-3.4-7.7-3.5-10.3-3.3C445.8,77.4,442.8,79.1,440.4,82.8z"
                    />
                    <path
                        class="st0"
                        d="M503.8,71.2c3.8-3.3,8.1-6,17.2-6.9v15.5l-5.8,0.9c-8.4,1.5-10.9,2.8-10.9,10.3v33.5h-17.5V65h17V71.2z"
                    />
                </g>
                <g>
                    <path
                        class="st1"
                        d="M211.7,43.8c2.4-0.9,4.8-1.6,7.3-2.3s4.8-1.2,7.3-1.7c4.8-1,9.6-1.8,14.4-2.4c9.6-1.2,19.3-1.7,28.9-1.7
				s19.3,0.6,28.9,1.8c4.8,0.7,9.6,1.4,14.4,2.4c2.4,0.5,4.8,1.1,7.3,1.7c2.4,0.7,4.8,1.4,7.3,2.3c0,0-25.5,3.3-57.8,3.3
				C237.1,47.2,211.7,43.8,211.7,43.8z"
                    />
                    <path
                        class="st1"
                        d="M220.9,1.3c9.1,3.2,22.7,10.4,48.5,10.4c27.3,0,39.4-7,48.5-10.4c-3.8,4.1-17.7,20-48.5,20
				C239.9,21.3,224.5,5,220.9,1.3z"
                    />
                    <path
                        class="st1"
                        d="M215.6,22c9.3,0,18.3,0.5,27.2,0.9c9,0.4,17.9,0.9,26.6,0.9s17.6-0.4,26.6-0.9c9-0.4,17.9-0.9,27.2-0.9
				c-4.2,2-8.6,3.5-13,4.9c-4.4,1.4-8.9,2.5-13.4,3.4c-9,1.9-18.2,2.9-27.6,3c-9.4,0-18.6-1.1-27.6-3c-4.5-1-9-2-13.4-3.4
				C224.1,25.5,219.8,23.9,215.6,22z"
                    />
                </g>
            </g>
            <g>
                <path
                    class="st2"
                    d="M257.6,141c1.1,0,2.2,0.2,3.1,0.5c1,0.3,1.8,0.8,2.4,1.5c0.7,0.7,1.2,1.5,1.6,2.5c0.4,1,0.6,2.2,0.6,3.6
			c0,1.7-0.2,3.4-0.8,5.1c-0.5,1.7-1.3,3.2-2.4,4.6c-1.1,1.4-2.3,2.4-3.8,3.3c-1.5,0.8-3.3,1.3-5.4,1.3c-1.1,0-2.2-0.2-3.2-0.5
			c-1-0.3-1.8-0.8-2.4-1.5c-0.7-0.7-1.2-1.5-1.6-2.5s-0.6-2.2-0.6-3.6c0-1.7,0.2-3.3,0.7-5.1s1.3-3.2,2.4-4.6
			c1.1-1.4,2.4-2.4,3.8-3.3C253.7,141.5,255.5,141,257.6,141z M253.3,160.7c1.4,0,2.6-0.3,3.8-1c1.1-0.7,2-1.5,2.9-2.6
			c0.7-1.1,1.4-2.3,1.8-3.6c0.4-1.3,0.7-2.7,0.7-4c0-1.8-0.4-3.3-1.2-4.3c-0.8-1.1-2-1.5-3.8-1.5c-1.4,0-2.6,0.3-3.8,1
			c-1.1,0.7-2,1.5-2.8,2.6c-0.7,1.1-1.4,2.3-1.8,3.6c-0.4,1.3-0.7,2.7-0.7,4c0,1.8,0.4,3.3,1.2,4.3
			C250.3,160.2,251.6,160.7,253.3,160.7z"
                />
                <path
                    class="st2"
                    d="M275.7,146.5c0.2,0,0.5,0,0.8,0s0.6,0.1,0.9,0.1c0.3,0.1,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.1,0.6,0.2l-0.8,2.5
			c-0.4-0.2-0.9-0.3-1.4-0.4c-0.6-0.1-1.1-0.2-1.5-0.2c-0.4,0-0.9,0-1.3,0.1s-0.8,0.2-1.1,0.2l-3.3,13.5h-2.9l3.7-15.4
			c0.8-0.3,1.7-0.5,2.7-0.7C273.8,146.6,274.7,146.5,275.7,146.5z"
                />
                <path
                    class="st2"
                    d="M281.7,166.2c0.8,0,1.5-0.1,2.2-0.3c0.7-0.2,1.2-0.5,1.6-0.9c0.5-0.4,0.8-0.8,1.1-1.4
			c0.3-0.6,0.5-1.1,0.7-1.8l0.2-0.6c-0.4,0.2-1,0.5-1.6,0.7c-0.7,0.2-1.4,0.3-2.1,0.3c-1.6,0-2.9-0.4-3.8-1.2
			c-0.9-0.8-1.3-2-1.3-3.5c0-1.4,0.2-2.7,0.7-4.1c0.5-1.3,1.1-2.4,2-3.5c0.9-1.1,2-1.9,3.4-2.4c1.4-0.7,3-1,4.8-1c0.3,0,0.6,0,1,0.1
			c0.3,0.1,0.7,0.1,1.1,0.2c0.4,0.1,0.7,0.2,1.1,0.3c0.3,0.2,0.7,0.2,1,0.4l-3.3,14c-0.2,1.1-0.6,2.1-1,3c-0.4,0.9-0.9,1.6-1.6,2.3
			c-0.7,0.7-1.5,1.1-2.6,1.5c-1.1,0.3-2.4,0.5-4,0.5c-0.9,0-1.8-0.1-2.6-0.3c-0.9-0.2-1.6-0.5-2.2-0.9l1.1-2.4
			c0.5,0.3,1.1,0.7,1.9,0.8C280,166.1,280.8,166.2,281.7,166.2z M284.3,159.8c0.8,0,1.5-0.2,2.3-0.4c0.7-0.3,1.2-0.6,1.5-0.8
			l2.3-9.4c-0.2-0.1-0.4-0.1-0.7-0.2c-0.2,0-0.5-0.1-0.7-0.1c-1.1,0-2.1,0.2-3,0.7c-0.9,0.4-1.7,1-2.4,1.7c-0.7,0.7-1.2,1.5-1.5,2.4
			c-0.3,0.9-0.6,1.9-0.6,2.9c0,1.1,0.2,1.9,0.7,2.4C282.6,159.6,283.3,159.8,284.3,159.8z"
                />
                <path
                    class="st2"
                    d="M305.7,163.1c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.2-0.2-0.6-0.2-0.9c-0.5,0.4-1.1,0.8-1.8,1.2
			c-0.7,0.4-1.7,0.7-2.8,0.7c-0.9,0-1.7-0.2-2.4-0.4c-0.7-0.3-1.1-0.7-1.5-1.2c-0.4-0.5-0.7-1.1-0.9-1.8c-0.2-0.7-0.3-1.5-0.3-2.3
			c0-1.4,0.2-2.7,0.7-4.1c0.5-1.3,1.1-2.4,2.1-3.5c0.9-1.1,2.1-1.9,3.5-2.5c1.4-0.7,3-1,4.9-1c0.6,0,1.2,0.1,2,0.2
			c0.7,0.2,1.4,0.3,1.9,0.7l-2.4,9.7c-0.1,0.5-0.2,0.9-0.2,1.4c-0.1,0.4-0.1,0.9-0.1,1.4c0,0.5,0,1,0.2,1.5c0.1,0.5,0.2,1,0.4,1.5
			L305.7,163.1z M301.2,160.9c0.9,0,1.6-0.2,2.2-0.6c0.7-0.4,1.1-0.8,1.6-1.3c0-0.3,0.1-0.7,0.1-1.1c0.1-0.4,0.1-0.7,0.2-1.1
			l1.9-7.7c-0.1-0.1-0.2-0.1-0.5-0.1c-0.2,0-0.4,0-0.7,0c-1.1,0-2.2,0.2-3.2,0.7c-1,0.5-1.7,1.1-2.4,1.8c-0.7,0.7-1.1,1.6-1.5,2.6
			c-0.3,1-0.6,2-0.6,3.1c0,0.4,0.1,0.9,0.2,1.3c0.1,0.4,0.2,0.8,0.4,1.1c0.2,0.3,0.5,0.7,0.9,0.8
			C300.1,160.7,300.5,160.9,301.2,160.9z"
                />
                <path
                    class="st2"
                    d="M314.4,162.9h-2.9l3.7-15.4c1-0.3,2-0.5,3.1-0.7c1.1-0.2,2.1-0.2,2.9-0.2c0.9,0,1.7,0.2,2.4,0.4
			s1.2,0.6,1.6,1.1c0.4,0.4,0.7,1,0.9,1.6s0.3,1.3,0.3,2c0,0.5-0.1,1-0.2,1.5s-0.2,1.1-0.3,1.5l-2,8.2h-2.9l1.8-7.6
			c0.2-0.6,0.2-1.1,0.4-1.8c0.2-0.6,0.2-1.1,0.2-1.7c0-0.8-0.2-1.5-0.7-2c-0.5-0.6-1.3-0.8-2.5-0.8c-0.5,0-1,0-1.5,0.1
			c-0.5,0.1-0.9,0.2-1.2,0.2L314.4,162.9z"
                />
                <path
                    class="st2"
                    d="M331.3,162.9h-2.9l3.8-16.1h2.9L331.3,162.9z M334.7,143.9c-0.5,0-0.9-0.2-1.2-0.4c-0.3-0.3-0.5-0.7-0.5-1.2
			c0-0.7,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.6,1.4-0.6s0.9,0.2,1.2,0.4c0.3,0.3,0.5,0.7,0.5,1.2c0,0.7-0.2,1.1-0.7,1.5
			C335.7,143.8,335.2,143.9,334.7,143.9z"
                />
                <path
                    class="st2"
                    d="M350,148.9c-0.6,0.6-1.4,1.3-2.4,2.3c-1,1-2.1,2-3.2,3.2c-1.1,1.1-2.2,2.2-3.2,3.3c-1,1.1-1.8,2-2.4,2.9h8.6
			l-0.6,2.4h-12l0.4-1.9c0.7-0.9,1.4-1.9,2.4-3c1-1.1,2-2.2,2.9-3.3c1-1.1,2-2.1,3-3.1c1-1,1.9-1.8,2.6-2.4h-7.7l0.6-2.4h11.4
			L350,148.9z"
                />
                <path
                    class="st2"
                    d="M357,163.3c-1.1,0-2-0.2-2.7-0.4c-0.7-0.3-1.4-0.7-1.9-1.2s-0.9-1.1-1.1-1.8c-0.2-0.7-0.4-1.5-0.4-2.2
			c0-1.5,0.2-2.9,0.7-4.2c0.4-1.3,1.1-2.5,1.9-3.6c0.8-1.1,1.8-1.9,2.9-2.4c1.1-0.6,2.4-0.9,3.9-0.9c0.8,0,1.5,0.1,2.2,0.3
			c0.6,0.2,1.1,0.5,1.5,0.9c0.4,0.4,0.7,0.8,0.8,1.3s0.2,1,0.2,1.5c0,1-0.2,1.8-0.5,2.4c-0.3,0.7-0.8,1.2-1.4,1.7s-1.2,0.8-2,1.1
			c-0.7,0.2-1.5,0.4-2.4,0.6s-1.7,0.2-2.5,0.3c-0.8,0.1-1.6,0.1-2.4,0.2c0,0.2,0,0.3,0,0.4s0,0.2,0,0.2c0,0.5,0.1,0.9,0.2,1.3
			c0.1,0.4,0.3,0.8,0.7,1.1s0.7,0.6,1.3,0.7c0.6,0.2,1.2,0.2,2.1,0.2c0.4,0,0.8,0,1.2-0.1s0.8-0.2,1.2-0.2c0.4-0.1,0.7-0.2,1-0.3
			c0.3-0.1,0.5-0.2,0.7-0.3l0.2,2.4c-0.4,0.2-1.1,0.5-2,0.7C359.2,163.2,358.2,163.3,357,163.3z M354,154.5c1.2-0.1,2.3-0.1,3.3-0.2
			c1-0.1,1.9-0.3,2.5-0.6c0.7-0.2,1.3-0.7,1.7-1.1s0.6-1.1,0.6-1.9c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.4-0.4-0.6
			c-0.2-0.2-0.4-0.3-0.7-0.4s-0.7-0.2-1.1-0.2c-0.7,0-1.4,0.2-2,0.5c-0.7,0.3-1.2,0.7-1.7,1.2c-0.5,0.5-0.9,1.1-1.3,1.8
			C354.4,153.1,354.2,153.7,354,154.5z"
                />
                <path
                    class="st2"
                    d="M388.4,159.6c0.5-0.7,1.1-1.5,1.7-2.5c0.7-1.1,1.3-2.1,1.9-3.3c0.7-1.1,1.2-2.4,1.7-3.6
			c0.6-1.2,1-2.3,1.2-3.3h2.9c-0.5,1.4-1.1,2.9-1.7,4.4c-0.7,1.5-1.5,2.9-2.3,4.4c-0.8,1.4-1.6,2.8-2.5,4c-0.9,1.2-1.7,2.4-2.5,3.3
			h-2.5c-0.2-1.8-0.4-3.6-0.6-5.4s-0.2-3.7-0.3-5.5c-0.4,0.9-0.9,1.9-1.4,2.9c-0.5,1-1.1,2-1.6,2.9c-0.6,1-1.1,1.9-1.7,2.8
			s-1.1,1.7-1.6,2.4h-2.5c-0.4-2.1-0.8-4.6-1.1-7.3c-0.2-2.7-0.4-5.6-0.5-8.8h2.9c0,1,0.1,2,0.1,3.2c0,1.1,0.1,2.3,0.2,3.4
			c0.1,1.1,0.2,2.3,0.2,3.3c0.1,1.1,0.2,2,0.3,2.9c0.7-0.9,1.3-1.9,1.9-2.9c0.7-1.1,1.2-2.1,1.8-3.3c0.6-1.1,1.1-2.2,1.5-3.3
			c0.5-1.1,0.9-2.2,1.2-3.2h2.6c0,0.9,0,2,0.1,3c0,1.1,0.1,2.1,0.2,3.3c0.1,1.1,0.2,2.2,0.2,3.3
			C388.3,157.5,388.4,158.5,388.4,159.6z"
                />
                <path
                    class="st2"
                    d="M400.3,162.9h-2.9l3.8-16.1h2.9L400.3,162.9z M403.6,143.9c-0.5,0-0.9-0.2-1.2-0.4c-0.3-0.3-0.5-0.7-0.5-1.2
			c0-0.7,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.6,1.4-0.6c0.5,0,0.9,0.2,1.2,0.4c0.3,0.3,0.5,0.7,0.5,1.2c0,0.7-0.2,1.1-0.7,1.5
			C404.6,143.8,404.1,143.9,403.6,143.9z"
                />
                <path
                    class="st2"
                    d="M410.6,163.3c-1.5,0-2.7-0.3-3.4-1s-1.1-1.6-1.1-2.9c0-0.8,0.2-2,0.4-3.3l3.3-13.7l3-0.5l-1.2,5h5.5l-0.6,2.4
			H411l-1.7,7.1c-0.2,1-0.4,1.8-0.4,2.5c0,0.7,0.2,1.1,0.6,1.5c0.4,0.3,1,0.4,1.9,0.4c0.6,0,1.1-0.1,1.8-0.3c0.6-0.2,1-0.4,1.3-0.6
			l0.2,2.4c-0.3,0.2-0.8,0.4-1.5,0.6C412.4,163.3,411.5,163.3,410.6,163.3z"
                />
                <path
                    class="st2"
                    d="M417.1,162.9l5.6-23.4l3-0.5l-2,8.1c0.5-0.2,1-0.3,1.5-0.4c0.5-0.1,1.1-0.2,1.5-0.2c0.9,0,1.7,0.2,2.4,0.4
			c0.7,0.2,1.2,0.6,1.6,1.1c0.4,0.4,0.7,1,0.9,1.6c0.2,0.7,0.3,1.3,0.3,2c0,0.9-0.2,2-0.4,3.1l-2,8.2h-2.9l1.8-7.6
			c0.2-0.6,0.2-1.1,0.4-1.8c0.2-0.6,0.2-1.1,0.2-1.7c0-0.8-0.2-1.5-0.7-2c-0.5-0.6-1.3-0.8-2.5-0.8c-0.5,0-1,0.1-1.5,0.2
			c-0.5,0.1-1,0.2-1.3,0.4l-3.2,13.4L417.1,162.9L417.1,162.9z"
                />
                <path
                    class="st2"
                    d="M445.3,160.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.1,0.8-0.2,1.1-0.4s0.7-0.4,0.8-0.7c0.2-0.3,0.3-0.7,0.3-1.2
			c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.2-0.5-0.5-0.7c-0.2-0.2-0.5-0.4-0.8-0.6s-0.7-0.4-1.2-0.7c-0.5-0.2-0.9-0.5-1.3-0.7
			c-0.4-0.2-0.7-0.5-1.1-0.8c-0.3-0.3-0.5-0.7-0.7-1.1c-0.2-0.4-0.2-0.9-0.2-1.5c0-1.5,0.6-2.8,1.6-3.8c1.1-0.9,2.7-1.4,4.8-1.4
			c1,0,1.9,0.1,2.6,0.3c0.7,0.2,1.3,0.4,1.5,0.6l-1.1,2.4c-0.3-0.2-0.7-0.3-1.4-0.5c-0.7-0.2-1.4-0.2-2.1-0.2
			c-0.4,0-0.8,0.1-1.1,0.2c-0.4,0.1-0.7,0.2-1.1,0.4c-0.3,0.2-0.6,0.5-0.7,0.7c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.6,0.2,1,0.7,1.4
			c0.4,0.3,1.1,0.7,2,1.1c0.6,0.2,1,0.5,1.5,0.7c0.4,0.2,0.8,0.6,1.1,0.9c0.3,0.3,0.5,0.7,0.7,1.1c0.2,0.4,0.2,1,0.2,1.5
			c0,0.9-0.2,1.6-0.5,2.3c-0.3,0.7-0.8,1.2-1.4,1.6s-1.3,0.7-2.1,1s-1.7,0.3-2.6,0.3c-0.7,0-1.3-0.1-1.9-0.2
			c-0.6-0.1-1.1-0.2-1.5-0.3c-0.4-0.2-0.7-0.2-1.1-0.4c-0.2-0.2-0.5-0.2-0.7-0.3l1.1-2.4c0.2,0.1,0.3,0.2,0.6,0.3s0.6,0.2,0.9,0.4
			c0.3,0.2,0.7,0.2,1.2,0.3C444.3,160.9,444.8,160.9,445.3,160.9z"
                />
                <path
                    class="st2"
                    d="M459.5,163.3c-1.5,0-2.7-0.3-3.4-1s-1.1-1.6-1.1-2.9c0-0.8,0.2-2,0.4-3.3l3.3-13.7l3-0.5l-1.2,5h5.5l-0.6,2.4
			h-5.5l-1.7,7.1c-0.2,1-0.4,1.8-0.4,2.5c0,0.7,0.2,1.1,0.6,1.5s1,0.4,1.9,0.4c0.6,0,1.1-0.1,1.8-0.3c0.6-0.2,1-0.4,1.3-0.6l0.2,2.4
			c-0.3,0.2-0.8,0.4-1.5,0.6C461.3,163.3,460.4,163.3,459.5,163.3z"
                />
                <path
                    class="st2"
                    d="M472.3,160.3c0.8-1.2,1.5-2.5,2.1-3.8c0.7-1.3,1.2-2.6,1.7-3.8c0.5-1.2,0.9-2.4,1.2-3.3
			c0.3-1,0.6-1.8,0.7-2.4h3c-0.2,0.8-0.6,1.7-1,2.9c-0.4,1.1-0.9,2.4-1.5,3.8c-0.6,1.3-1.2,2.7-2,4.2c-0.7,1.4-1.5,2.8-2.4,4.1
			c-0.7,1-1.3,1.9-2,2.7c-0.7,0.8-1.4,1.6-2.1,2.2c-0.7,0.7-1.5,1.1-2.4,1.5c-0.9,0.3-2,0.6-3,0.6c-0.6,0-1.1-0.1-1.6-0.2
			c-0.5-0.2-0.8-0.3-1.1-0.5l1-2.4c0.2,0.2,0.5,0.3,0.8,0.4c0.3,0.1,0.7,0.2,1.1,0.2c1.2,0,2.2-0.3,3-0.9c0.8-0.6,1.6-1.3,2.4-2.3
			c-0.7-2.4-1.4-5.1-1.9-7.8c-0.5-2.8-0.8-5.5-0.9-8.4h2.9c0,0.9,0.1,2,0.2,3c0.1,1.1,0.2,2.2,0.3,3.4c0.2,1.1,0.3,2.4,0.6,3.6
			C471.7,158,472,159.2,472.3,160.3z"
                />
                <path
                    class="st2"
                    d="M485,155.8c-0.1,0.5-0.2,0.9-0.3,1.3s-0.2,0.8-0.2,1.2c0,0.7,0.2,1.2,0.5,1.6c0.3,0.4,1,0.7,1.9,0.7l-0.7,2.4
			c-0.8,0-1.5-0.2-2.1-0.4c-0.6-0.2-1.1-0.6-1.4-1s-0.7-0.8-0.8-1.4c-0.2-0.6-0.2-1.1-0.2-1.8c0-0.5,0.1-1,0.2-1.5
			c0.1-0.5,0.2-1.1,0.3-1.6l3.9-16.2l3-0.5L485,155.8z"
                />
                <path
                    class="st2"
                    d="M495.8,163.3c-1.1,0-2-0.2-2.7-0.4c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-0.9-1.1-1.1-1.8
			c-0.2-0.7-0.4-1.5-0.4-2.2c0-1.5,0.2-2.9,0.7-4.2c0.4-1.3,1.1-2.5,1.9-3.6s1.8-1.9,2.9-2.4c1.1-0.6,2.4-0.9,3.9-0.9
			c0.8,0,1.5,0.1,2.2,0.3c0.6,0.2,1.1,0.5,1.5,0.9c0.4,0.4,0.7,0.8,0.8,1.3s0.2,1,0.2,1.5c0,1-0.2,1.8-0.5,2.4
			c-0.3,0.7-0.8,1.2-1.4,1.7s-1.2,0.8-2,1.1c-0.7,0.2-1.5,0.4-2.4,0.6c-0.8,0.2-1.7,0.2-2.5,0.3c-0.8,0.1-1.6,0.1-2.4,0.2
			c0,0.2,0,0.3,0,0.4s0,0.2,0,0.2c0,0.5,0.1,0.9,0.2,1.3c0.1,0.4,0.3,0.8,0.7,1.1c0.3,0.3,0.7,0.6,1.3,0.7c0.6,0.2,1.2,0.2,2.1,0.2
			c0.4,0,0.8,0,1.2-0.1s0.8-0.2,1.2-0.2c0.4-0.1,0.7-0.2,1-0.3c0.3-0.1,0.5-0.2,0.7-0.3l0.2,2.4c-0.4,0.2-1.1,0.5-2,0.7
			C498.1,163.2,497.1,163.3,495.8,163.3z M492.9,154.5c1.2-0.1,2.3-0.1,3.3-0.2c1-0.1,1.9-0.3,2.5-0.6c0.7-0.2,1.3-0.7,1.7-1.1
			s0.6-1.1,0.6-1.9c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.7-0.4c-0.3-0.1-0.7-0.2-1.1-0.2
			c-0.7,0-1.4,0.2-2,0.5c-0.7,0.3-1.2,0.7-1.7,1.2c-0.5,0.5-0.9,1.1-1.3,1.8C493.3,153.1,493.1,153.7,492.9,154.5z"
                />
            </g>
        </g>
    </svg>
</template>

<style type="text/css">
.st0 {
    fill: #030504;
}

.st1 {
    fill: #ff8189;
}

.st2 {
    fill: #181716;
}
</style>
